import React from 'react';
import st from './index.module.css';
import classNames from 'classnames';

const Checkbox = ({ name, label, checked, onChange }) => {
  return (
    <label className="relative pl-[35px] cursor-pointer text-[16px] flex items-center">
      <input className="absolute cursor-pointer opacity-0" onChange={onChange} type="checkbox" name={name} checked={checked} />
      <span
        className={classNames(
          st['checkmark'],
          `checkmark absolute left-0 w-[24px] h-[24px] border-1 rounded-[5px] ${checked ? 'border-[#09AF77]' : 'border-black'}`
        )}
      />
      {label}
    </label>
  );
};

export default Checkbox;
