import React, { forwardRef } from 'react';
import classNames from 'classnames';

import st from './index.module.css';

const ArrowButton = forwardRef(({ shape, direction, className, onClick, ...rest }, ref) => {
  const cl = classNames(st['button'], st[shape], className);

  return (
    <button className={cl} onClick={onClick} {...rest} ref={ref}>
      {direction === 'left' ? (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0L9.41 1.41L3.83 7H16V9H3.83L9.41 14.59L8 16L0 8L8 0Z" fill="currentColor" />
        </svg>
      ) : (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="currentColor" />
        </svg>
      )}
    </button>
  );
});

ArrowButton.displayName = 'ArrowButton';

export default ArrowButton;
