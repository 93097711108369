import React from 'react';

const Input = ({ label, name, type = 'text', placeholder, value, onChange, error }) => {
  return (
    <div className="mb-4 w-full flex flex-col gap-[6px]">
      <label className="text-18 font-medium leading-15 text-gray-headerIcon" htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete="off"
        className="border-b-1 border-blackD py-10 bg-transparent focus:outline-none focus:placeholder-transparent placeholder-gray-headerIcon"
      />

      {error && <span className="text-red-600 animate-warning">{error}</span>}
    </div>
  );
};

export default Input;
