import React, { useEffect, useState } from 'react';
import { Button } from './ui';

const Layout = ({ children, country, showPopup, hide }) => {
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 400) {
        setIsScrollTopVisible(true);
      } else {
        setIsScrollTopVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fakeScamTranslations = {
    english: {
      title: 'Warning: Fake Job Scams',
      content:
        'We want to warn you about some people who are pretending to offer jobs at our company. These offers are not real, and we are not connected to them and bear no responsibility for their actions. Please do not give them your personal information or money.',
    },
    spanish: {
      title: 'Advertencia: Estafas de falsos empleos',
      content:
        'Queremos advertirse sobre algunas personas que están pretendiendo ofrecer puestos de trabajo en nuestra empresa. Estas ofertas no son reales, no tenemos ninguna relación con ellos y no somos responsables de sus acciones. Por favor, no les dé su información personal ni dinero.',
    },
    french: {
      title: 'Avertissement : arnaques aux faux emplois',
      content:
        "Nous souhaitons vous mettre en garde contre certaines personnes qui prétendent proposer des emplois dans notre entreprise. Ces offres ne sont pas réelles, nous n'avons aucun lien avec ces personnes et nous ne sommes pas responsables de leurs actions. Nous vous demandons de ne pas leur donner vos informations personnelles ou de l'argent.",
    },
  };

  const countriesLanguage = {
    AR: 'spanish',
    BO: 'spanish',
    BF: 'french',
    TD: 'french',
    CG: 'french',
    EC: 'spanish',
    SV: 'spanish',
    GH: 'english',
    GT: 'spanish',
    HT: 'french',
    HN: 'spanish',
    IN: 'english',
    JM: 'english',
    KE: 'english',
    MG: 'french',
    MW: 'english',
    NI: 'spanish',
    NE: 'french',
    NG: 'english',
    PK: 'english',
    RW: 'french',
    SN: 'french',
    SD: 'english',
    SS: 'english',
    TZ: 'english',
    TN: 'french',
    UG: 'english',
    ZM: 'english',
    ZW: 'english',
    LK: 'english',
    VE: 'spanish',
    DO: 'spanish',
  };

  const isBlockedCountry = Boolean(countriesLanguage[country]);
  const isBrowser = () => typeof window !== 'undefined';

  const scrollToTop = () => {
    if (!isBrowser()) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="app">
      <Button
        onClick={scrollToTop}
        variant="vertical"
        narrowing="both"
        mode="light"
        className={`gap-[5px] fixed bg-white w-[117px] h-[36px] md:text-[16px] z-1000 transition-all duration-[3000] ease leading-[20px] ${isScrollTopVisible ? ' visible opacity-100' : ' invisible opacity-0'} `}
      >
        To Top
        <img className={'rotate-90'} src="https://admin.weprodigi.com/uploads/images/arrow.svg" alt="arrow" />
      </Button>

      {isBlockedCountry && (
        <style>{`
          input[type=submit],button[type=submit]{
            pointer-events:none;
            opacity:0.5
          }
        `}</style>
      )}
      {children}
      <div
        className={
          'fixed z-900 bg-navbar w-full h-full left-0 top-0 right-0 bottom-0 items-center justify-center' +
          ' ' +
          (showPopup && isBlockedCountry ? 'flex' : 'hidden')
        }
      >
        <div className="relative transform w-11/12 max-w-screen-md max-h-screen-md bg-gray border border-gray-transparent rounded-5 md:scale-60 2xl:scale-100">
          <div
            className="absolute cursor-pointer font-medium text-[26px] right-[-20px] w-[40px] h-[40px] top-[-20px] bg-gray text-center rounded-[20px]"
            onClick={() => hide()}
          >
            X
          </div>
          <div className="py-23 px-50">
            <p className="text-purple uppercase text-24 text-center font-black leading-none">
              {fakeScamTranslations[countriesLanguage[country]]
                ? fakeScamTranslations[countriesLanguage[country]].title
                : fakeScamTranslations.english.title}
            </p>
            <p className={'mt-20'}>
              {fakeScamTranslations[countriesLanguage[country]]
                ? fakeScamTranslations[countriesLanguage[country]].content
                : fakeScamTranslations.english.content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
