import classNames from 'classnames';
import React, { useState } from 'react';
import st from './index.module.css';

const Segment = ({ options, initialSelectedOptions = '', onChange, error, className }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);

  const handleClick = (option, videoRef) => {
    setSelectedOptions(option);
    onChange(option);

    if (videoRef) {
      if (videoRef.paused) {
        videoRef.play();
      } else {
        videoRef.pause();
      }
    }
  };

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-y-[8px] gap-x-[30px] ">
      {options.map(({ label, value, content }, idx) => (
        <div key={idx} className="flex flex-row gap-[8px]">
          <div
            onClick={e => handleClick(value, e.currentTarget.nextSibling?.querySelector('video'))}
            className={classNames(
              st[className],
              `${selectedOptions === value && `${st[`${className}_active`]}`}`,
              'text-black cursor-pointer group relative flex-1'
            )}
          >
            {label}
          </div>
          <div
            className="md:hidden flex w-[89px] h-[68px] rounded-[5px] overflow-hidden"
            onClick={e => handleClick(value, e.currentTarget.querySelector('video'))}
          >
            {content}
          </div>
        </div>
      ))}
      {error && <span className="text-red-600 animate-warning">{error}</span>}
    </div>
  );
};

export default Segment;
