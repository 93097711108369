import classNames from 'classnames';
import React, { useState } from 'react';
import st from './index.module.css';

const Segment = ({ options, initialSelectedOptions = [], onChange, error, className }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);

  const handleClick = option => {
    if (selectedOptions.includes(option)) {
      const newSelectedOptions = selectedOptions.filter(item => item !== option);
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    } else {
      const newSelectedOptions = [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    }
  };

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-y-[8px] gap-x-[30px]">
      {options.map(({ label, value }, idx) => (
        <div
          key={idx}
          onClick={() => handleClick(value)}
          className={classNames(st[className], `${selectedOptions.includes(value) && `${st[`${className}_active`]}`}`)}
        >
          {label}
        </div>
      ))}
      {error && <span className="text-red-600 animate-warning">{error}</span>}
    </div>
  );
};

export default Segment;
