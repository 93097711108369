import React from 'react';
import classNames from 'classnames';

import st from './index.module.css';

const Button = ({ variant, mode, narrowing, className, children, onClick }) => {
  const cl = classNames(st['button'], st[narrowing], st[variant], st[mode], className);

  return (
    <button className={cl} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
