import classNames from 'classnames';
import React, { useState } from 'react';
import st from './index.module.css';

const Segment = ({ options, initialSelectedOptions = '', onChange, error, className }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);

  const handleClick = option => {
    setSelectedOptions(option);
    onChange(option);
  };

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-y-[8px] gap-x-[30px] ">
      {options.map(({ label, value, content }, idx) => (
        <div key={idx} className="flex flex-row gap-[8px]">
          <div
            onClick={() => handleClick(value)}
            className={classNames(
              st[className],
              `${selectedOptions === value && `${st[`${className}_active`]}`}`,
              'text-black cursor-pointer group relative flex-1'
            )}
          >
            {label}
            <div className="absolute top-[45px] right-[-100px] z-[999] rounded-[5px] overflow-hidden  w-[142px] h-[80px] hidden group-hover:block">
              {content}
            </div>
          </div>
          <div className="md:hidden flex w-[89px] h-[68px]">{content}</div>
        </div>
      ))}
      {error && <span className="text-red-600 animate-warning">{error}</span>}
    </div>
  );
};

export default Segment;
